import React, {useState} from "react";
import Header from "../../component/header";
import Footer from "../../component/footer";
import Interaction from "./interaction";
import Classtest from "./classtest";
import Popup from "./popup";
import Player from 'griffith'

let datainit=[
    {className:'inter-montain',title:'高山', info: '高山:雄心壮志',showdata:'“高山”气势峥嵘，可寄托诗人雄心壮志。如杜甫《望岳》中“会当凌绝顶，一览众山小”表达出诗人兼济天下的入世情怀。'},
    {className:'inter-montain2',title:'空山', info: '空山:幽静清冷',showdata:'“空山”杳无人迹，可渲染幽静清冷的气氛。如王维在《鹿柴》写道：“空山不见人，但闻人语响”，便是借空山表现诗人的空阔虚无之感。'},
    {className:'inter-pubu',title:'急水', info: '急水:豪迈气势',showdata:'急水渲染豪迈的气势，如李白《望庐山瀑布》中“飞流直下三千尺，疑是银河落九天”的汹涌之水。'},
    {className:'inter-zhu',title:'缓水', info: '缓水:离情别绪',showdata:'缓水承载离情别绪，如王勃《山中》 “长江悲已滞，万里念将归”的愁苦之水。'},
    {className:'inter-moon',title:'明月', info: '明月:思念、鸿鹄远志',showdata:'1.表达对故乡的思念：李白的《静夜思》里“举头望明月，低头思故乡”即表达了对故乡的思念。\n' +
            '2.表达对情人的思恋：南北朝谢庄的《月赋》中的“美人迈兮音尘阙,隔千里兮共明月”即表达了对情人的思恋。\n' +
            '3.寄托鸿鹄远志：唐代李白的《宣州谢朓楼饯别校书叔云》中“俱怀逸兴壮思飞，欲上青天揽明月”即寄托了诗人远大的抱负。\n' +
            '\n'},
    {className:'inter-sun',title:'太阳', info: '日:太阳，多代表希望，积极向上',
        showdata:'（1）太阳意象可给人舒适温暖之感，使人联想起幸福惬意的家庭生活。'+
            '斜阳照墟落,穷巷牛羊归。——王维《渭川田家》'+
            '关中平原古老宁静、夕阳晚照。日暮人归,勾起人们对家庭温馨的向往。'+
            '（2）太阳以其夺目的光芒成为天上的君主，可作为人间皇帝的象征。' +
            '总为浮云能避日，长安不见使人愁。——李白《登金陵凤凰台》'+
            '“浮云蔽日”暗喻小人当道，君臣阻隔，表达出诗人仕途不顺的壮志难酬。'+
            '（3）黄昏时的落日，可营造萧瑟的离别氛围。' +
            '日暮飞鸟还，行人去不息。——王维《送黎拾遗》'+
            '外出的鸟儿都回来了，诗人仍守望友人愈行愈远，写出诗人送友的深切惆怅。'
    }
];

let datainit2=[
    {className:'inter-qiao',title:'樵夫', info: '人物意象：樵夫',showdata:'樵夫是山水诗中常见的人物意象。\n' +
            '遥想故山下，樵夫应滞行。——出自唐代姚合的《郡中对雪》。\n' +
            '欲投人处宿，隔水问樵夫。——出自唐·王维《终南山》\n'},
    {className:'inter-huan',title:'浣女', info: '人物意象：浣女',showdata:'浣女是山水诗中常见的人物意象。\n' +
            '王维的《山居秋暝》中便有：“竹喧归浣女，莲动下渔舟。”\n' +
            '晴江一女浣，朝日众鸡鸣。——出自唐·王维《晓行巴峡》。\n'}
]

function Course (){
    const [show,setShow] =useState(false);
    const [data,setData] =useState(datainit);
    const [data2,setData2] =useState(datainit2);
    const [alertData,setAlertData] =useState(null);

    function showAlert(dataInfo) {
        setAlertData(dataInfo);
        setShow(true);
    }

    function closePopup(){
        setShow(false);
    }

    return (
        <div>
            <div>
                <Header/>
                 <div className="page course-page">
                    {/*<Player  className='MP4tab'*/}
                    {/*    autoplay='true'*/}
                    {/*    sources={{*/}
                    {/*    hd:{play_url:"//f.video.weibocdn.com/o0/OggSWuAZlx082RjiUDLy010412005Nm30E010.mp4?label=mp4_hd&template=654x368.25.0&media_id=4865074150703233&tp=8x8A3El:YTkl0eM8&us=0&ori=1&bf=4&ot=h&ps=3lckmu&uid=6lC3NN&ab=9298-g4,8224-g0,7397-g1,3601-g32,8143-g0,8013-g0,7598-g0&Expires=1675995194&ssig=QWWqm1mk%2FB&KID=unistore,video"},*/}
                    {/*    sd:{play_url:"//f.video.weibocdn.com/o0/OggSWuAZlx082RjiUDLy010412005Nm30E010.mp4?label=mp4_hd&template=654x368.25.0&media_id=4865074150703233&tp=8x8A3El:YTkl0eM8&us=0&ori=1&bf=4&ot=h&ps=3lckmu&uid=6lC3NN&ab=9298-g4,8224-g0,7397-g1,3601-g32,8143-g0,8013-g0,7598-g0&Expires=1675995194&ssig=QWWqm1mk%2FB&KID=unistore,video"},*/}
                    {/*}*/}
                    {/*} />*/}

                     <video className='MP4tab' loop muted
                            controls
                             playsinline="true"
                          x5-video-player-type="h5"
                             >
                        <source src={require("../../common/img/courseMp4/smalldemo.mp4")} type='video/mp4' />
                     </video>

                     <Classtest/>

                     <Interaction
                         data={data}
                         data2={data2}
                         showAlert = {showAlert}
                     />
                     <Footer/>
                </div>
            </div>
            {show?<Popup
                data={alertData}
                closePopup = {closePopup}
            />:""}
        </div>
    )
}

export default Course;