import React, {useState} from "react";

export default function Cardlist(props) {
    let newList = [];

    // 定义判断是否点赞的函数
    function getIsGood(ele){
        ele.isGood = !ele.isGood;
         props.list.forEach((item, index) => {
             newList.push(item);
         });
         props.setList(newList);
    }

    return(
        <>
            {
                props.list.map(ele=> {
                    return (
                         <div className="cardbox" key={ele.id}>
                            <img src={ele.imgUrl} alt="" />
                            <div className="cardwrapper">
                                <p className="good">有{ele.goodNum}人觉得很赞</p>
                                <span className={"iconfont icon-tuijian "+(ele.isGood?"isGood":"")} onClick={() => { getIsGood(ele) }}></span>
                            </div>

                        </div>
                    )

                })
            }
        </>

    )
}