import React from "react";
import {Link} from "react-router-dom";



export default function Classtest() {

    return(

             <div className="page-con">
                 <div className="classtest">
                     <div className="problem-inner">
                         <p className="desc">课堂同步测试</p>

                        <Link to="/course/partone">
                            <img src={require("../../common/img/classtest/button1.png")} id="classtestbutton"/>
                        </Link>

                         <Link to="/course/parttwo">
                            <img src={require("../../common/img/classtest/button2.png")} id="classtestbutton"/>
                        </Link>

                         <Link to="/course/partthree">
                            <img src={require("../../common/img/classtest/button3.png")} id="classtestbutton"/>
                        </Link>

                         <Link to="/course/partfour">
                            <img src={require("../../common/img/classtest/button4.png")} id="classtestbutton"/>
                        </Link>

                     </div>
                 </div>
             </div>
    )
}