import React, {createRef, useMemo, useState} from "react";
import Headerback from "../../../component/headerback";
import Question from "../../pretest/question";


const defalutschema = {
  id: 1,
  desc: '意象篇',
  totalScore: 0,
  question: [
    {
      id: 1,
      desc: '以下意象反映的思想感情，错误的一项是()',
      the_score: 0,
      options: [
        {
          label: '山水诗中有许多经典的人物意象，如樵夫就是一个典型意象。',
          value: '山水诗中有许多经典的人物意象，如樵夫就是一个典型意象。',
          score: 0,
          selected: false
        },
        {
          label: '“举头望明月，低头思故乡”是借月亮寄托思乡之情。',
          value: '“举头望明月，低头思故乡”是借月亮寄托思乡之情。',
          score: 0,
          selected: false
        },
        {
          label: '山可寄托诗人雄心壮志。如王维在《鹿柴》写道：“空山不见人，但闻人语响”，便是借山寄托雄心。',
          value: '山可寄托诗人雄心壮志。如王维在《鹿柴》写道：“空山不见人，但闻人语响”，便是借山寄托雄心。',
          score: 1,
          selected: false
        }
      ],
      selected: [

      ]
    },
      {
        id: 2,
      desc: '以下意象常出现在山水诗中的一项是()',
      the_score: 0,
      options: [
        {
          label: '红豆',
          value: '红豆',
          score: 0,
          selected: false
        },
        {
          label: '水',
          value: '水',
          score: 1,
          selected: false
        },
        {
          label: '精卫',
          value: '精卫',
          score: 0,
          selected: false
        }
      ],
      selected: [

      ]
      }
  ]
}

window.localStorage.schema = JSON.stringify(defalutschema);

let a_schema;
try {
  a_schema = JSON.parse(window.localStorage.schema);
} catch { };

let refs = [];


export default function Parttwo() {
   const [schema, setSchema] = useState(a_schema);
    let { question, desc, outScore } = schema;
    const [finish, setFinish] = useState(false);
    const [finishScore, setFinishScore] = useState(0);
    const [disabled, setDisabled] = useState(false);

    let totalScore;

    let newSchema = {
        id: 1,
        totalScore: 0,
        question: [],
      };

    const handleSave = () => {
        document.getElementById("btn").setAttribute("disabled", true)
        document.getElementById("btn").onclick = "return false;";
        document.getElementById("btn").style.backgroundColor = "gray";
        document.getElementById("btn").style.cursor = "default";
        document.getElementById("btn").style.display="none";

        setFinish(true);
        totalScore = 0;
        schema.question.forEach((item, index) => {
          const { getSchema } = refs[index].current;
          newSchema.question.push(getSchema());
          if (getSchema().the_score) {
            totalScore += getSchema().the_score;
          }

        });
        newSchema.totalScore = totalScore;
        window.localStorage.schema = JSON.stringify(newSchema);
        setDisabled(true);
        setFinishScore(newSchema.totalScore);
        alert(`你本次答对${newSchema.totalScore}道题!\n 1、解析：“高山”气势峥嵘，可寄托诗人雄心壮志。如杜甫《望岳》中“会当凌绝顶，一览众山小”表达出诗人兼济天下的入世情怀。“空山不见人，但闻人语响”是借“空山”表现诗人的空阔虚无之感。所以C项错误。\n 2、解析：A项“红豆”是相思豆，借指男女爱情的信物，常出现在描写爱情的诗中。如王维《相思》中写道：“红豆生南国，春来发几枝；愿君多采撷，此物最相思”。B项“水”以不同的样态寄寓”怒”或“哀”，常出现在山水诗中。C项中“精卫”有“精卫填海”的典故，所以“精卫”常用来比喻有深仇大恨，立志必报；也比喻不畏艰难，努力奋斗。`)
      }

      useMemo(() => {
        refs = question.map(item => createRef());
      }, [question])

    return(
        <div>
            <Headerback
                showPage={1}
            />
            <div className="page course-page">
                 <div className="page-con">
                     <div className="parttwothree">
                         <div className="problem-inner">
                             <p className="desc">{desc}</p>
                            {
                                 schema.question.map((item, index) => {
                                  return (
                                    <div key={item.id} className="question">
                                      <p className="title">{item.id}、{item.desc}</p>
                                       <Question finish={finish} item={item} ref={refs[index]} yanz={[1,2,1]}></Question>
                                    </div>)
                                })
                             }
                         </div>
                     </div>
                     <button className="miaov-btn miaov-btn-md problem-btn" type="submit" onClick={handleSave} id ='btn'>提交</button>
                 </div>
            </div>

        </div>
    )
}
