import React,{Component} from "react";
import Header from "../../component/header";
import {Link} from "react-router-dom";
export default class Home extends Component{
    render() {
        return (
            <div>
                <Header/>
                <div className="page home-page">
                    <div className="page-con">
                        {/*<Link to="/pretest" className="button-pretest"></Link>*/}
                        <div className="hometitle">
                             <img src={require("../../common/img/home/home_title.png")} id="homebutton"/>
                        </div>

                        <div className="threebutton">
                            <Link to="/pretest">
                                <img src={require("../../common/img/home/button1.png")} id="homebutton"/>
                            </Link>
                             <Link to="/course">
                                <img src={require("../../common/img/home/button2.png")} id="homebutton"/>
                            </Link>
                             <Link to="/afterclass">
                                <img src={require("../../common/img/home/button3.png")} id="homebutton"/>
                            </Link>
                            <Link to={"https://g.h5gd.com/p/nexylppr"}>
                                <img src={require("../../common/img/home/button4.png")} id="homebutton"/>
                            </Link>

                            {/*<img src={require("../../common/img/button1.png")} id="homebutton"/>*/}
                            {/*<img src={require("../../common/img/button2.png")} id="homebutton"/>*/}
                            {/*<img src={require("../../common/img/button3.png")} id="homebutton"/>*/}
                        </div>

                    </div>
                </div>
            </div>
            )
    }
}
