import React, {createRef, useMemo, useState} from "react";
import Headerback from "../../../component/headerback";
import Question from "../../pretest/question";


const defalutschema = {
  id: 1,
  desc: '情感篇',
  totalScore: 0,
  question: [
    {
      id: 1,
      desc: '以下诗句表达的情感，错误的一项是()',
      the_score: 0,
      options: [
        {
          label: '山水诗可以传达诗人不满现实，怀才不遇的悲怆。',
          value: '山水诗可以传达诗人不满现实，怀才不遇的悲怆。',
          score: 0,
          selected: false
        },
        {
          label: '因为山水诗是吟诵山水的诗歌，所以不能寄托禅意。',
          value: '因为山水诗是吟诵山水的诗歌，所以不能寄托禅意。',
          score: 1,
          selected: false
        },
        {
          label: '山水诗可以表达对自然风光、壮丽山河的热爱。',
          value: '山水诗可以表达对自然风光、壮丽山河的热爱。',
          score: 0,
          selected: false
        }
      ],
      selected: [

      ]
    },
      {
        id: 2,
      desc: '以下诗句中，表达的情感与其他诗句不同的一项是()',
      the_score: 0,
      options: [
        {
          label: '况属高风晚，山山黄叶飞。（《山中》王勃）',
          value: '况属高风晚，山山黄叶飞。（《山中》王勃）',
          score: 0,
          selected: false
        },
        {
          label: '两岸青山相对出，孤帆一片日边来。（《望天门山》李白）',
          value: '两岸青山相对出，孤帆一片日边来。（《望天门山》李白）',
          score: 1,
          selected: false
        },
        {
          label: '朝辞白帝彩云间，千里江陵一日还。（《早发白帝城》李白）',
          value: '朝辞白帝彩云间，千里江陵一日还。（《早发白帝城》李白）',
          score: 0,
          selected: false
        }
      ],
      selected: [

      ]
      }
  ]
}

window.localStorage.schema = JSON.stringify(defalutschema);

let a_schema;
try {
  a_schema = JSON.parse(window.localStorage.schema);
} catch { };

let refs = [];


export default function Partthree() {
  const [schema, setSchema] = useState(a_schema);
    let { question, desc, outScore } = schema;
    const [finish, setFinish] = useState(false);
    const [finishScore, setFinishScore] = useState(0);
    const [disabled, setDisabled] = useState(false);

    let totalScore;

    let newSchema = {
        id: 1,
        totalScore: 0,
        question: [],
      };

    const handleSave = () => {
        document.getElementById("btn").setAttribute("disabled", true)
        document.getElementById("btn").onclick = "return false;";
        document.getElementById("btn").style.backgroundColor = "gray";
        document.getElementById("btn").style.cursor = "default";
        document.getElementById("btn").style.display="none";

        setFinish(true);
        totalScore = 0;
        schema.question.forEach((item, index) => {
          const { getSchema } = refs[index].current;
          newSchema.question.push(getSchema());
          if (getSchema().the_score) {
            totalScore += getSchema().the_score;
          }

        });
        newSchema.totalScore = totalScore;
        window.localStorage.schema = JSON.stringify(newSchema);
        setDisabled(true);
        setFinishScore(newSchema.totalScore);
        alert(`你本次答对${newSchema.totalScore}道题!\n 1、解析：山水诗可以寄托禅意，显露人生哲理。如王维在《过香积寺》中写道：“薄暮空潭曲，安禅制毒龙。”诗人将“安禅”、“毒龙”等佛教意象融于诗中，喻指只有克服邪念妄想，才能悟到禅理的高深。所以B项错误。\n 2、解析：A项“况属高风晚，山山黄叶飞。” 写出萧瑟秋风、飘零黄叶的景象，表达出诗人的萧瑟心境和飘零悲伤之感。B项“两岸青山相对出，孤帆一片日边来。” 意思是青山远迎，孤帆乘风破浪，越来越靠近天门山的情景，赞美了大自然的神奇壮丽，表达了作者乐观豪迈的感情。C项“朝辞白帝彩云间，千里江陵一日还。”意思是清晨“我”告别高入云霄的白帝城，江陵远在千里船行只一日时间。诗人把遇赦后愉快的心情和江山的壮丽多姿、顺水行舟的流畅轻快融为一体表达出来。B项和C项都是表达乐观、愉快的心情，而A项表达悲伤的感情，故A项不同。`)
      }

      useMemo(() => {
        refs = question.map(item => createRef());
      }, [question])

    return(
        <div>
            <Headerback
                showPage={1}
            />
            <div className="page course-page">
                 <div className="page-con">
                     <div className="parttwothree">
                         <div className="problem-inner">
                             <p className="desc">{desc}</p>
                            {
                                 schema.question.map((item, index) => {
                                  return (
                                    <div key={item.id} className="question">
                                      <p className="title">{item.id}、{item.desc}</p>
                                       <Question finish={finish} item={item} ref={refs[index]} yanz={[1,2,1]}></Question>
                                    </div>)
                                })
                             }
                         </div>
                     </div>
                     <button className="miaov-btn miaov-btn-md problem-btn" type="submit" onClick={handleSave} id ='btn'>提交</button>
                 </div>
            </div>

        </div>
    )
}
