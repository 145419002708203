import React, {createRef, useMemo, useState} from "react";
import Headerback from "../../../component/headerback";
import Question from "../../pretest/question";

let gapPro=[
//     （1）远上寒山石径斜，白云生处有人家。（《山行》杜牧）
// （2）春潮带雨晚来急，野渡无人舟自横。（《滁州西涧》韦应物）
// （3）月落乌啼霜满天，江枫渔火对愁眠。（《枫桥夜泊》张继）
// （4）野旷天低树，江清月近人。（《宿建德江》孟浩然）
// （5）人闲桂花落，夜静春山空。（《鸟鸣涧》王维）
// （6）相看两不厌，只有敬亭山。（《独坐敬亭山》李白）

    {idPro: 1, upper: '远上寒山石径斜，', next: '白云生处有人家。', title:'（《山行》杜牧）', show: -1},
    {idPro:2, upper: '春潮带雨晚来急，', next: '野渡无人舟自横。', title:'（《滁州西涧》韦应物）', show: -1},
    {idPro:3, upper: '月落乌啼霜满天，', next: '江枫渔火对愁眠。', title:'（《《枫桥夜泊》张继）', show: 1},
    {idPro:4, upper: '野旷天低树，', next: '江清月近人。', title:'（《宿建德江》孟浩然）', show: 1},
    {idPro:5, upper: '人闲桂花落，', next: '夜静春山空。', title:'（《鸟鸣涧》王维）', show: 1},
    {idPro:6, upper: '相看两不厌，', next: '只有敬亭山。', title:'（《独坐敬亭山》李白）', show: -1},
];


const defalutschema = {
  id: 1,
  desc: '小学3-6年级',
  totalScore: 0,
  question: [
    {
      id: 2,
      desc: '关于“人闲桂花落，夜静春山空。月出惊山鸟，时鸣春涧中。”（《鸟鸣涧》王维）说法错误的是()',
      the_score: 0,
      options: [
        {
          label: '这首诗运用以动衬静的手法，以动态的“花落”、“月出”、“鸟鸣”衬托山静。',
          value: '这首诗运用以动衬静的手法，以动态的“花落”、“月出”、“鸟鸣”衬托山静。',
          score: 0,
          selected: false
        },
        {
          label: '“月出惊山鸟，时鸣春涧中。”两句写月亮从山谷中升起惊动了山中的鸟，诗人也因此受到惊吓。',
          value: '“月出惊山鸟，时鸣春涧中。”两句写月亮从山谷中升起惊动了山中的鸟，诗人也因此受到惊吓。',
          score: 1,
          selected: false
        },
        {
          label: '这首诗主要写春山夜景，描写春山夜晚非常幽静的景象。',
          value: '这首诗主要写春山夜景，描写春山夜晚非常幽静的景象。',
          score: 0,
          selected: false
        }
      ],
      selected: [

      ]
    }
  ]
}

window.localStorage.schema = JSON.stringify(defalutschema);

let a_schema;
try {
  a_schema = JSON.parse(window.localStorage.schema);
} catch { };

let refs = [];

    // 在一个数组中随机抽取几个组成另外一个数组
var numPool = [0, 1, 2, 3, 4, 5];
var filteredPool = [];
let randIndex;
// 通过一个for循环对原数组numPool做一个遍历，如果这个数存在于excludePool中，就将结果放到filteredPool中：
while (filteredPool.length !== 3) {
    randIndex = Math.floor(Math.random() * numPool.length)
    if (filteredPool.indexOf(numPool[randIndex]) === -1) {
        filteredPool.push(numPool[randIndex]);
    }
}

export default function Classtwo() {
    const [schema, setSchema] = useState(a_schema);
    let { question, desc, outScore } = schema;
    const [finish, setFinish] = useState(false);
    const [finishScore, setFinishScore] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [show,setShow] =useState([-1,-1,-1]);
    let {inputValue1,inputValue2,inputValue3} = useState(['']);

    let totalScore;

    let newSchema = {
        id: 1,
        totalScore: 0,
        question: [],
      };

    const handleSave = () => {
        document.getElementById("btn").setAttribute("disabled", true)
        document.getElementById("btn").onclick = "return false;";
        document.getElementById("btn").style.backgroundColor = "gray";
        document.getElementById("btn").style.cursor = "default";
        document.getElementById("btn").style.display="none";

        setFinish(true);
        totalScore = 0;
        schema.question.forEach((item, index) => {
          const { getSchema } = refs[index].current;
          newSchema.question.push(getSchema());
          if (getSchema().the_score) {
            totalScore += getSchema().the_score;
          }

        });

        let trueAnswer = [];
        // 判断填空题的对错
        filteredPool.map((item, index) => {
            if (index === 0){
                if (gapPro[item].show === -1){
                    trueAnswer.push(gapPro[item].next.replace(/。/g, ""))
                    if (inputValue1 === gapPro[item].next.replace(/。/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                } else if (gapPro[item].show === 1){
                    trueAnswer.push(gapPro[item].upper.replace(/，/g, ""))
                    if (inputValue1 === gapPro[item].upper.replace(/，/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                }
            }else if (index === 1){
                if (gapPro[item].show === -1){
                    trueAnswer.push(gapPro[item].next.replace(/。/g, ""))
                    if (inputValue2 === gapPro[item].next.replace(/。/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                } else if (gapPro[item].show === 1){
                    trueAnswer.push(gapPro[item].upper.replace(/，/g, ""))
                    if (inputValue2 === gapPro[item].upper.replace(/，/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                }

            }else if (index === 2){
                if (gapPro[item].show === -1){
                    trueAnswer.push(gapPro[item].next.replace(/。/g, ""))
                    if (inputValue3 === gapPro[item].next.replace(/。/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                } else if (gapPro[item].show === 1){
                    trueAnswer.push(gapPro[item].upper.replace(/，/g, ""))
                    if (inputValue3 === gapPro[item].upper.replace(/，/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                }
            }

        })

        newSchema.totalScore = totalScore;
        window.localStorage.schema = JSON.stringify(newSchema);
        setDisabled(true);
        setFinishScore(newSchema.totalScore);
        alert(`你本次答对${newSchema.totalScore}道题!\n 1、答案： \n（1）${trueAnswer[0]}\n（2）${trueAnswer[1]}\n（3）${trueAnswer[2]} \n 2、解析：前两句从远景入手，所以A项中“近景”是错误的。`)
      }

      useMemo(() => {
        refs = question.map(item => createRef());
      }, [question])



     let flag = 0;
    const getPro = (item) => {
        flag ++;
        if (item.show === -1) {
            if (flag === 1){
                return (
                 <div className="wrapper">
                      ({flag})&nbsp;&nbsp;{item.upper}
                     <input type="text" className="question-input" onChange={changeInput1}/>。
                     {getTag (flag-1, show)}
                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                </div>
                )
            }else if (flag === 2){
                return (
                 <div className="wrapper">
                      ({flag})&nbsp;&nbsp;{item.upper}
                     <input type="text" className="question-input" onChange={changeInput2}/>。
                     {getTag (flag-1, show)}
                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                </div>
                )
            }else if (flag===3){
                return (
                 <div className="wrapper">
                      ({flag})&nbsp;&nbsp;{item.upper}
                     <input type="text" className="question-input" onChange={changeInput3}/>。
                     {getTag (flag-1, show)}
                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                </div>
                )
            }

        }
        else if (item.show === 1){
            if (flag === 1){
                return (
                     <div className="wrapper">
                          ({flag})&nbsp;&nbsp;
                         <input type="text" className="question-input"  onChange={changeInput1}/>，{item.next}
                         {getTag (flag-1, show)}
                         <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                    </div>
                )
            }else if (flag === 2){
                return (
                     <div className="wrapper">
                          ({flag})&nbsp;&nbsp;
                         <input type="text" className="question-input"  onChange={changeInput2}/>，{item.next}
                        {getTag (flag-1, show)}
                         <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                    </div>
                )
            }else if (flag===3){
                 return (
                     <div className="wrapper">
                          ({flag})&nbsp;&nbsp;
                         <input type="text" className="question-input"  onChange={changeInput3}/>，{item.next}
                         {getTag (flag-1, show)}
                         <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                    </div>
                )
            }
        }

    }

     // 获取输入的值
    const changeInput1 = (e) => {
        inputValue1 = e.target.value;
    }
    const changeInput2 = (e) => {
        inputValue2 = e.target.value;
    }
    const changeInput3 = (e) => {
        inputValue3 = e.target.value;
    }

    const getTag = (index, show) => {
        if (show[index] === 0) {
            return (<div className="cuoicon iconfont icon-cuo"></div>)
        } else if (show[index] === 1) {
            return (<div className="duiicon iconfont icon-zhengque-correct"></div>)
        } else {
            return "";
        }
    }

    return(
        <div>
            <Headerback
                showPage={2}
            />
            <div className="page course-page">
                 <div className="page-con">
                     <div className="problem">
                         <div className="problem-inner">
                             <p className="desc">{desc}</p>

                              <div className="question">
                                 <p className="title1">1、补全诗句</p>
                                 {
                                     filteredPool.map((item, index) => {
                                         return(
                                             getPro(gapPro[item])
                                         )
                                     })
                                 }
                             </div>

                             {
                                 schema.question.map((item, index) => {
                                  return (
                                    <div key={item.id} className="question">
                                      <p className="title">{item.id}、{item.desc}</p>
                                       <Question finish={finish} item={item} ref={refs[index]} yanz={[1,2,1]}></Question>
                                    </div>)
                                })
                             }

                         </div>
                     </div>
                     <button className="miaov-btn miaov-btn-md problem-btn" type="submit" onClick={handleSave} id ='btn'>提交</button>
                 </div>
            </div>
        </div>
    )

}