import React from "react";
import {Link} from "react-router-dom";


export default function Aftertest() {

    return(

             <div className="page-con">
                 <div className="classtest">
                     <div className="problem-inner">
                         <p className="desc">课后练习</p>

                        <Link to="/afterclass/classone">
                            <img src={require("../../common/img/aftertest/button1.png")} id="classtestbutton"/>
                        </Link>

                         <Link to="/afterclass/classtwo">
                            <img src={require("../../common/img/aftertest/button2.png")} id="classtestbutton"/>
                        </Link>

                         <Link to="/afterclass/classthree">
                            <img src={require("../../common/img/aftertest/button3.png")} id="classtestbutton"/>
                        </Link>

                         <Link to="/afterclass/classfour">
                            <img src={require("../../common/img/aftertest/button4.png")} id="classtestbutton"/>
                        </Link>

                     </div>
                 </div>
             </div>
    )
}