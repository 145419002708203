import React from "react";
import Headerback from "../../component/headerback";

export default function Erweima() {

    return (
        <div>
            <Headerback
                showPage={2}
            />

             <div className="page">
                <div className="page-con">
                     <img src={require("../../common/img/classtest/erweima.jpg")}/>
                </div>
            </div>

        </div>
    )
}
