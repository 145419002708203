import {Radio} from 'antd-mobile'
import React, {forwardRef, useImperativeHandle, useState } from 'react';


let score = 0;
const char = ['A', 'B', 'C',];


const Question = (props, ref) => {
    const { finish, yanz } = props;
    const { id, desc, options } = props.item;
    const [newSchema, setNewSchema] = useState({});
    // const [yanz, setYanz] = useState([1, 2, 1]);

    useImperativeHandle(ref, () => {
        return {
            getSchema: () => {
                return newSchema
            }
        }
    })
    const getTag = (index, finish, yanz) => {
        if (yanz[index] === 1 && finish) {
            return (<div className="cuoicon2 iconfont icon-cuo"></div>)
        } else if (yanz[index] === 2 && finish) {
            return (<div className="duiicon2 iconfont icon-zhengque-correct"></div>)
        } else {
            return "";
        }
    }
    const onChange = (item_temp,index_temp) => {
        item_temp.selected = true;
        let flag = 1;
        score = 0;
        let selectedArr = [];
        // setYanz([1, 2, 1])
        options.forEach((item, index) => {
             if(index_temp !== index){
                item.selected = false;
              }

            if (item.selected && item.score !== 0) {
                score += parseInt(item.score);
                selectedArr.push(item);
                // const newYanz = yanz;
                // newYanz.splice(index, 1, 2);
                // setYanz(newYanz)
                // console.log(122, yanz);
            } else if ((item.selected && item.score === 0)) {
                flag = 0;
                // const cuoYanz = yanz;
                // cuoYanz.splice(index, 1, 1);
                // setYanz(cuoYanz)
                //  console.log(222, yanz);
            }
        })
        if (!flag) {
            score = 0;
        }
        const schema = {
            id,
            desc,
            the_score: score,
            options: options,
            selected: selectedArr
        }
        setNewSchema(schema);
    }

    return (

        <div className=''>
            {
                <Radio.Group>
                    {
                        options.map((item, index) => (
                            <div className="wrapper">
                                {getTag(index, finish, yanz)}
                                <Radio value={index} style={{'--font-size':'15px'}} onChange={() => { onChange(item, index) }}>{char[index]}、{item.label}</Radio>
                            </div>
                    ))
                    }
                </Radio.Group>
            }
        </div>
    )
};

export default forwardRef(Question);
