import React from "react";
import Header from "../../component/header";
import Footer from "../../component/footer";
import {Link, useNavigate} from "react-router-dom"
import {Input} from "antd-mobile";
import Aftertest from "./aftertest";
import Resource from "./resource";

let tuijianCard=[
     {search:'枫叶'},
     {search:'飞雪'},
     {search:'竹林'},
     {search:'瀑布'},
     {search:'安禅'},
     {search:'太阳'},
     {search:'明月'},
     {search:'莲舟'}
];

export default function Afterclass (){
    const navigate = useNavigate();

    function goSearch() {
        //跳转搜索界面
        navigate('/search')
    }

    return (
        <div>
            <Header/>
             <div className="page afterclass-page">
                <div className="page-con">
                    <div className="search-kongbai"></div>

                    <div className="search-form">
                        <div className="search-container" onClick={goSearch}>
                            <i className="search-container i"></i>
                            <Input className="search-container input" placeholder="搜索知识卡片"/>
                        </div>
                    </div>

                    <div className="search_tuijian_content">
                        <div className="search_tuijian_title">推荐搜索</div>
                        <div className="search_tuijian_cardContent">
                            {
                                tuijianCard.map(ele=>{

                                    return <div key={ele.id} className="search_tuijian_name">{ele.search}</div>
                                })
                            }

                        </div>

                    </div>

                    <Aftertest/>
                    <div className="page-con">
                         <div className="wenjuan">
                             <div className="problem-inner">
                                 <p className="desc"><a href="https://www.wjx.cn/vm/rc9CkL5.aspx#">微课教学反馈问卷</a></p>

                             </div>
                         </div>
                     </div>

                    <div className="page-con">
                         <div className="wenjuan">
                             <div className="problem-inner">
                                  <Link to="/afterclass/jiaoliuqun">
                                      <p className="desc">学习交流群</p>
                                  </Link>


                             </div>
                         </div>
                     </div>

                 <Resource/>

                </div>


                 <Footer/>
            </div>

        </div>
    )

}
