import React,{Component} from "react";
import {Routes, Route} from "react-router-dom"
import Home from "./view/home";
import Pretest from "./view/pretest";
import Course from "./view/course";
import Afterclass from "./view/afterclass";
import Partone from "./view/course/classtest/partone";
import Parttwo from "./view/course/classtest/parttwo";
import Partthree from "./view/course/classtest/partthree";
import Partfour from "./view/course/classtest/partfour";
import "./common/css/style.css"
import "./common/css/myFont.css"
import Searchresult from "./view/afterclass/searchresult";
import Classone from "./view/afterclass/aftertest/classone";
import Classtwo from "./view/afterclass/aftertest/classtwo";
import Classthree from "./view/afterclass/aftertest/classthree";
import Classfour from "./view/afterclass/aftertest/classfour";
import Erweima from "./view/afterclass/erweima";

class App extends Component{
  render() {
    return (
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pretest" element={<Pretest />} />
          <Route path="/course" element={<Course />} />
          <Route path="/afterclass" element={<Afterclass />} />
          <Route path="/search" element={<Searchresult />} />
          <Route path="/course/partone" element={<Partone />} />
          <Route path="/course/parttwo" element={<Parttwo />} />
          <Route path="/course/partthree" element={<Partthree />} />
          <Route path="/course/partfour" element={<Partfour />} />
          <Route path="/afterclass/classone" element={<Classone />} />
          <Route path="/afterclass/classtwo" element={<Classtwo />} />
          <Route path="/afterclass/classthree" element={<Classthree />} />
          <Route path="/afterclass/classfour" element={<Classfour />} />
          <Route path="/afterclass/jiaoliuqun" element={<Erweima />} />

      </Routes>
    );
  }
}

export default App;
