import React from "react";
import {useNavigate} from "react-router-dom"

export default function Headerback (props) {
    let {showPage} = props;
    const navigate = useNavigate();

    function goBack(showPage) {
         // 返回上一层地址路径
        if (showPage === 1) {
            navigate('/course')
        } else if (showPage === 2) {
            navigate('/afterclass')
        }
    }

    return (
        <headerback id="header">
            <span  className="header-btn-left iconfont icon-fanhui1" onClick={() => { goBack(showPage) }}></span>
            {/*<img src={require("../common/img/logo.png")} id="logo"/>*/}
            <span className="header-btn-right iconfont icon-yonghu"></span>

        </headerback>
    )

}