import React,{useEffect,useRef} from "react";
// import BScroll from "better-scroll"

export default function Popup (props){
    let {data,closePopup} = props;
    // let popupCon = useRef(null);
    // useEffect(()=>{
    //     let bscroll = new  BScroll(popupCon.current, {bounce:true});
    // },[]);

    return(
        <div className="popup"
            onTouchEnd={closePopup}
        >
            <div className="popup-win"
                onTouchEnd={(e)=>{
                    e.stopPropagation();
                }}
            >
                <div className="popup-photo"></div>
                <h3 className="popup-title">{data.title}</h3>
                <div className="popup-con"
                     // ref={popupCon}
                >
                    <br/>
                    {data.showdata}
                </div>
                <span className="close" onTouchEnd={closePopup}></span>
            </div>
        </div>
    );
}