import React from "react";

export default function Footer() {
    return (
        <footer>
            <div className="footer-nav">
                <p className="miaov-contact clear">
                    <a className="iconfont icon-youxiang" href="mailto:***@****.edu.cn">xxxxxx@xxxx.edu.cn</a>
                    <a className="iconfont icon-24gl-telephone2" href="tel:***-********">000-99999999</a>
                </p>
                {/*<nav className="clear">*/}
                {/*    <a>首页</a>*/}
                {/*    <a>课前检测</a>*/}
                {/*    <a>课堂学习</a>*/}
                {/*    <a>课后巩固</a>*/}
                {/*</nav>*/}
            </div>
            <p className="footer-copy">中国大学生计算机设计大赛微课类作品</p>
        </footer>
    );
}