import React from "react";
import {useNavigate} from "react-router-dom";

export default function Header () {
    const navigate = useNavigate();

    function goHome() {
        //跳转搜索界面
        navigate('/')
    }

    return (
        <header id="header">
            <span className="header-btn-left iconfont icon-fanhui2" onClick={goHome}></span>
            {/*<img src={require("../common/img/logo.png")} id="logo"/>*/}
            <span className="header-btn-right iconfont icon-yonghu"></span>

            {/*<Link to="/" className="header-btn-right iconfont icon-icon7"></Link>*/}
            {/*/!*<span className="header-btn-right">山水诗</span>*!/*/}
            {/*<img src={require("../common/img/logo.png")} id="logo"/>*/}
            {/*<a href="javascript:;" className="header-btn-left iconfont icon-hycaidan"></a>*/}
        </header>
    )

}