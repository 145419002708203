import React from "react";

export default function Resource() {
    return(
        <div className="page-con">
            <div className="resource_tui">
                 <div className="problem-inner">
                     <p className="desc">课外资源延伸</p>
                        <p className="title1">1、中国诗词网：</p>
                            <div className="wrapper">
                                (1) <a href="https://www.zgshici.com/etagid19608b0/">https://www.zgshici.com/etagid19608b0/</a>
                            </div>
                        <p className="title1">2、古诗词学习网：</p>
                            <div className="wrapper">
                                (2) <a href="http://www.881023.com/">http://www.881023.com/</a>
                            </div>
                        <p className="title1">3、古诗词网：</p>
                            <div className="wrapper">
                                (3) <a href="https://shici.com.cn/">https://shici.com.cn/</a>
                            </div>
                        <p className="title1">4、品诗文网：</p>
                            <div className="wrapper">
                                (4) <a href="https://m.pinshiwen.com/">https://m.pinshiwen.com/</a>
                            </div>
                 </div>
            </div>

            {/*<h2 className="inter-title">课外资料推荐</h2>*/}
            {/*<ul className="inter-con">*/}
            {/*     <li className="resource1">*/}
            {/*        <a href="#">*/}
            {/*            <div className="inter-box">*/}
            {/*                <h3 className="inter-title">title</h3>*/}
            {/*                <p className="inter-info">info</p>*/}
            {/*            </div>*/}
            {/*        </a>*/}
            {/*    </li>*/}
            {/*</ul>*/}

         </div>
    )

}
