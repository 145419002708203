import React from "react";

export default function Interaction(props) {
    let {data,data2, showAlert} = props;

    return(
        <section className="inter">
            <h2 className="inter-title">重难点学习——景物意象</h2>
            <ul className="inter-con">
                {
                    data.map(item=>{
                        let point = {};
                        return (
                             <li className={item.className}
                                 onTouchStart={(e)=>{
                                    let touch = e.changedTouches[0];
                                    point.x = touch.pageX;
                                    point.y = touch.pageY;
                                }}
                                onTouchEnd={(e)=>{
                                     let touch = e.changedTouches[0];
                                    let nowPoint = {
                                        x : touch.pageX,
                                        y : touch.pageY
                                    };
                                    if(Math.abs(nowPoint.x-point.x)<5&&Math.abs(nowPoint.y-point.y)<5){
                                        showAlert(item);
                                    }
                                }}
                             >
                                <a href="#">
                                    <div className="inter-box">
                                        <h3 className="inter-title">{item.title}</h3>
                                        <p className="inter-info">{item.info}</p>
                                    </div>
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
            <h2 className="inter-title">重难点学习——人物意象</h2>
            <ul className="inter-con">
                {
                    data2.map(item=>{
                        let point = {};
                        return (
                             <li className={item.className}
                                 onTouchStart={(e)=>{
                                    let touch = e.changedTouches[0];
                                    point.x = touch.pageX;
                                    point.y = touch.pageY;
                                }}
                                onTouchEnd={(e)=>{
                                     let touch = e.changedTouches[0];
                                    let nowPoint = {
                                        x : touch.pageX,
                                        y : touch.pageY
                                    };
                                    if(Math.abs(nowPoint.x-point.x)<5&&Math.abs(nowPoint.y-point.y)<5){
                                        showAlert(item);
                                    }
                                }}
                             >
                                <a href="#">
                                    <div className="inter-box">
                                        <h3 className="inter-title">{item.title}</h3>
                                        <p className="inter-info">{item.info}</p>
                                    </div>
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </section>
    )

}
