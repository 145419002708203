import React,{ createRef, useMemo, useState} from "react";
import Header from "../../component/header";
import Footer from "../../component/footer";
import Tab from "../../component/tab";
import Question from "./question";

let tabImge=[
    require("../../common/img/tab/img4.png"),
    require("../../common/img/tab/img1.png"),
    require("../../common/img/tab/img2.png"),
    require("../../common/img/tab/img3.png")
];

const defalutschema = {
  id: 1,
  desc: '课前测试题',
  totalScore: 0,
  question: [
    {
      id: 2,
      desc: '（单选题）“日照香炉生紫烟，遥看瀑布挂前川。飞流直下三千尺，疑是银河落九天。”（《望庐山瀑布》李白），对这首诗理解不正确的一项是（）?',
      the_score: 0,
      options: [
        {
          label: '第一、二句的意思是香炉峰在阳光的照射下生起紫色烟霞，远远望见瀑布似白色绢绸悬挂在山前。',
          value: '第一、二句的意思是香炉峰在阳光的照射下生起紫色烟霞，远远望见瀑布似白色绢绸悬挂在山前。',
          score: 0,
          selected: false
        },
        {
          label: '“疑是银河落九天”中的“银河落九天”是指银河从天的最高外落下来。',
          value: '”疑是银河落九天”中的“银河落九天”是指银河从天的最高外落下来。',
          score: 1,
          selected: false
        },
        {
          label: '第三、四句用夸张的比喻和浪漫的想象，进一步描绘瀑布的形象和气势。',
          value: '第三、四句用夸张的比喻和浪漫的想象，进一步描绘瀑布的形象和气势。',
          score: 0,
          selected: false
        }
      ],
      selected: [

      ]
    }
  ]
}

window.localStorage.schema = JSON.stringify(defalutschema);

let a_schema;
try {
  a_schema = JSON.parse(window.localStorage.schema);
} catch { };

let refs = [];

function Pretest (){
      const [show,setShow] =useState([-1,-1,-1]);
      const [schema, setSchema] = useState(a_schema);
      const [finish, setFinish] = useState(false);
      const [finishScore, setFinishScore] = useState(0);
      const [disabled, setDisabled] = useState(false);
      let { question, desc, outScore } = schema;
      let totalScore;
      let {inputValue1,inputValue2,inputValue3} = useState(['']);
      let newSchema = {
        id: 1,
        totalScore: 0,
        question: [],
      };
      const handleSave = () => {
          document.getElementById("btn").setAttribute("disabled", true)
        document.getElementById("btn").onclick = "return false;";
        document.getElementById("btn").style.backgroundColor = "gray";
        document.getElementById("btn").style.cursor = "default";
        document.getElementById("btn").style.display="none";

        setFinish(true);
        totalScore = 0;
        schema.question.forEach((item, index) => {
          const { getSchema } = refs[index].current;
          newSchema.question.push(getSchema());
          if (getSchema().the_score) {
            totalScore += getSchema().the_score;
          }

        });
        // 判断填空题的对错
          if (inputValue1 === '复照青苔上'){
              totalScore += 1;
              const newShow = show;
              newShow.splice(0, -1, 1);
              setShow(newShow)
          }else {
              const newShow = show;
              newShow.splice(0, -1, 0);
              setShow(newShow)
          }

          if (inputValue2 === '夜发清溪向三峡'){
              totalScore += 1;
              const newShow = show;
              newShow.splice(1, -1, 1);
              setShow(newShow)
          }else {
              const newShow = show;
              newShow.splice(1, -1, 0);
              setShow(newShow)
          }


          if (inputValue3 === '白云千载空悠悠'){
              totalScore += 1;
              const newShow = show;
              newShow.splice(2, -1, 1);
              setShow(newShow)
          }else {
              const newShow = show;
              newShow.splice(2, -1, 0);
              setShow(newShow)
          }

        newSchema.totalScore = totalScore;
        window.localStorage.schema = JSON.stringify(newSchema);
        setDisabled(true);
        setFinishScore(newSchema.totalScore);
        alert(`你本次答对${newSchema.totalScore}道题!\n1、答案：\n（1）复照青苔上\n（2）夜发清溪向三峡\n（3）白云千载空悠悠\n2、解析：“疑是”一词说明本句的意思是高崖上飞腾直落的瀑布好像有几千尺，让人恍惚以为是银河从天上泻落到人间，并不是真的“银河”掉下来。所以B项错误。`)
      }

       useMemo(() => {
        refs = question.map(item => createRef());
      }, [question])


    // 获取输入的值
    const changeInput1 = (e) => {
        inputValue1 = e.target.value;
    }
    const changeInput2 = (e) => {
        inputValue2 = e.target.value;
    }
    const changeInput3 = (e) => {
        inputValue3 = e.target.value;
        // 白云千载空悠悠
    }

    const getTag = (index, show) => {
        if (show[index] === 0) {
            return (<div className="cuoicon iconfont icon-cuo"></div>)
        } else if (show[index] === 1) {
            return (<div className="duiicon iconfont icon-zhengque-correct"></div>)
        } else {
            return "";
        }
    }


    return (
        <div>
            <Header/>
             <div className="page">
                <Tab
                    className="banner"
                    data={tabImge}
                    renderItem={
                        (item)=>{
                            return (
                                <img src={item}/>
                            );
                        }
                    }
                />
                 <div className="page-con">
                     <div className="problem">
                        <div className="problem-inner">
                            <p className="desc">{desc}</p>
                            {/*{finish ? <div className="totalScore">{finishScore}</div> :<div className="totalScore"></div>}*/}

                             <div className="question">
                                 <p className="title1">1、补全诗句</p>
                                 <div className="wrapper">
                                     (1)&nbsp;&nbsp;返景入深林，
                                     <input type="text" className="question-input" onChange={changeInput1}/>。
                                     {getTag (0, show)}
                                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——（《鹿柴》王维）
                                 </div>
                                 <div className="wrapper">
                                     (2)&nbsp;&nbsp;
                                     <input type="text" className="question-input" onChange={changeInput2}/>，思君不见下渝州。
                                     {getTag (1, show)}
                                     {/*{show[1]?<div className="duicuo1">对</div>:<div className="duicuo1">错</div>}*/}
                                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——（《峨眉山月歌》李白）
                                </div>
                                 <div className="wrapper">
                                     (3)&nbsp;&nbsp;黄鹤一去不复返，
                                     <input type="text" className="question-input" onChange={changeInput3}/>。
                                     {getTag (2, show)}
                                     {/*{show[2]?<div className="duicuo1">对</div>:<div className="duicuo1">错</div>}*/}
                                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——（《黄鹤楼》崔颢）
                                 </div>
                             </div>

                            {
                            schema.question.map((item, index) => {
                              return (
                                <div key={item.id} className="question">
                                  <p className="title">{item.id}、{item.desc}</p>
                                   <Question finish={finish} item={item} ref={refs[index]} yanz={[1,2,1]}></Question>
                                </div>)
                            })
                          }


                        </div>
                     </div>
                     <button className="miaov-btn miaov-btn-md problem-btn" type="submit" onClick={handleSave} id ='btn'>提交</button>
                 </div>

                 <Footer/>
            </div>

        </div>
    )
}
export default Pretest;
