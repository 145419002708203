import React, {createRef, useMemo, useState} from "react";
import Headerback from "../../../component/headerback";
import Question from "../../pretest/question";


let gapPro=[
// （1）艰难苦恨繁霜鬓，潦倒新停浊酒杯。（《登高》杜甫）
// （2）风急天高猿啸哀，渚清沙白鸟飞回。（《登高》杜甫）
// （3）无边落木萧萧下，不尽长江滚滚来。（《登高》杜甫）
// （4）落木千山天远大，澄江一道月分明。（《登快阁》黄庭坚）
// （5）朱弦已为佳人绝，青眼聊因美酒横。（《登快阁》黄庭坚）
// （6）万里归船弄长笛，此心吾与白鸥盟。（《登快阁》黄庭坚）

    {idPro: 1, upper: '艰难苦恨繁霜鬓，', next: '潦倒新停浊酒杯。', title:'（《登高》杜甫）', show: 1},
    {idPro:2, upper: '风急天高猿啸哀，', next: '渚清沙白鸟飞回。', title:'（《登高》杜甫）', show: -1},
    {idPro:3, upper: '无边落木萧萧下，', next: '不尽长江滚滚来。', title:'（《登高》杜甫）', show: 1},
    {idPro:4, upper: '落木千山天远大，', next: '澄江一道月分明。', title:'（《登快阁》黄庭坚）', show: -1},
    {idPro:5, upper: '朱弦已为佳人绝，', next: '青眼聊因美酒横。', title:'（《登快阁》黄庭坚）', show: -1},
    {idPro:6, upper: '万里归船弄长笛，', next: '此心吾与白鸥盟。', title:'（《登快阁》黄庭坚）', show: -1},
];


const defalutschema = {
  id: 1,
  desc: '高中',
  totalScore: 0,
  question: [
    {
      id: 2,
      desc: '关于“风急天高猿啸哀，渚清沙白鸟飞回。无边落木萧萧下，不尽长江滚滚来。万里悲秋常作客，百年多病独登台。艰难苦恨繁霜鬓，潦倒新停浊酒杯。”这首诗（《登高》杜甫），理解错误的一项是()',
      the_score: 0,
      options: [
        {
          label: '首联用“风急”二字带动全联，其中风、渚，天、沙，猿啸、鸟飞，对仗工整。',
          value: '首联用“风急”二字带动全联，其中风、渚，天、沙，猿啸、鸟飞，对仗工整。',
          score: 1,
          selected: false
        },
        {
          label: '颔联“萧萧”“滚滚” 使“无边落木”“不尽长江”更加形象，景象萧瑟、阔远。',
          value: '颔联“萧萧”“滚滚” 使“无边落木”“不尽长江”更加形象，景象萧瑟、阔远。',
          score: 0,
          selected: false
        },
        {
          label: '“悲秋”二字是诗眼，前四句抒发登台所感的悲情，后四句描写登台所见的秋景。',
          value: '“悲秋”二字是诗眼，前四句抒发登台所感的悲情，后四句描写登台所见的秋景。',
          score: 0,
          selected: false
        }
      ],
      selected: [

      ]
    }
  ]
}

window.localStorage.schema = JSON.stringify(defalutschema);

let a_schema;
try {
  a_schema = JSON.parse(window.localStorage.schema);
} catch { };

let refs = [];

 // 在一个数组中随机抽取几个组成另外一个数组
var numPool = [0, 1, 2, 3, 4, 5];
var filteredPool = [];
let randIndex;
// 通过一个for循环对原数组numPool做一个遍历，如果这个数存在于excludePool中，就将结果放到filteredPool中：
while (filteredPool.length !== 3) {
    randIndex = Math.floor(Math.random() * numPool.length)
    if (filteredPool.indexOf(numPool[randIndex]) === -1) {
        filteredPool.push(numPool[randIndex]);
    }
}

export default function Classfour() {
    const [schema, setSchema] = useState(a_schema);
    let { question, desc, outScore } = schema;
    const [finish, setFinish] = useState(false);
    const [finishScore, setFinishScore] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [show,setShow] =useState([-1,-1,-1]);
    let {inputValue1,inputValue2,inputValue3} = useState(['']);

    let totalScore;

    let newSchema = {
        id: 1,
        totalScore: 0,
        question: [],
      };

    const handleSave = () => {
        document.getElementById("btn").setAttribute("disabled", true)
        document.getElementById("btn").onclick = "return false;";
        document.getElementById("btn").style.backgroundColor = "gray";
        document.getElementById("btn").style.cursor = "default";
        document.getElementById("btn").style.display="none";

        setFinish(true);
        totalScore = 0;
        schema.question.forEach((item, index) => {
          const { getSchema } = refs[index].current;
          newSchema.question.push(getSchema());
          if (getSchema().the_score) {
            totalScore += getSchema().the_score;
          }

        });

        let trueAnswer = [];
        // 判断填空题的对错
        filteredPool.map((item, index) => {
            if (index === 0){
                if (gapPro[item].show === -1){
                    trueAnswer.push(gapPro[item].next.replace(/。/g, ""))
                    if (inputValue1 === gapPro[item].next.replace(/。/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                } else if (gapPro[item].show === 1){
                    trueAnswer.push(gapPro[item].upper.replace(/，/g, ""))
                    if (inputValue1 === gapPro[item].upper.replace(/，/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                }
            }else if (index === 1){
                if (gapPro[item].show === -1){
                    trueAnswer.push(gapPro[item].next.replace(/。/g, ""))
                    if (inputValue2 === gapPro[item].next.replace(/。/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                } else if (gapPro[item].show === 1){
                    trueAnswer.push(gapPro[item].upper.replace(/，/g, ""))
                    if (inputValue2 === gapPro[item].upper.replace(/，/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                }

            }else if (index === 2){
                if (gapPro[item].show === -1){
                    trueAnswer.push(gapPro[item].next.replace(/。/g, ""))
                    if (inputValue3 === gapPro[item].next.replace(/。/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                } else if (gapPro[item].show === 1){
                    trueAnswer.push(gapPro[item].upper.replace(/，/g, ""))
                    if (inputValue3 === gapPro[item].upper.replace(/，/g, "")){
                        totalScore += 1;
                        const newShow = show;
                        newShow.splice(0, -1, 1);
                        setShow(newShow)
                    }else {
                        const newShow = show;
                        newShow.splice(0, -1, 0);
                        setShow(newShow)
                    }
                }
            }

        })

        newSchema.totalScore = totalScore;
        window.localStorage.schema = JSON.stringify(newSchema);
        setDisabled(true);
        setFinishScore(newSchema.totalScore);
        alert(`你本次答对${newSchema.totalScore}道题!\n 1、答案： \n（1）${trueAnswer[0]}\n（2）${trueAnswer[1]}\n（3）${trueAnswer[2]} \n 2、解析：前两句从远景入手，所以A项中“近景”是错误的。`)
      }

      useMemo(() => {
        refs = question.map(item => createRef());
      }, [question])


    let flag = 0;
    const getPro = (item) => {
        flag ++;
        if (item.show === -1) {
            if (flag === 1){
                return (
                 <div className="wrapper">
                      ({flag})&nbsp;&nbsp;{item.upper}
                     <input type="text" className="question-input" onChange={changeInput1}/>。
                     {getTag (flag-1, show)}
                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                </div>
                )
            }else if (flag === 2){
                return (
                 <div className="wrapper">
                      ({flag})&nbsp;&nbsp;{item.upper}
                     <input type="text" className="question-input" onChange={changeInput2}/>。
                     {getTag (flag-1, show)}
                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                </div>
                )
            }else if (flag===3){
                return (
                 <div className="wrapper">
                      ({flag})&nbsp;&nbsp;{item.upper}
                     <input type="text" className="question-input" onChange={changeInput3}/>。
                     {getTag (flag-1, show)}
                     <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                </div>
                )
            }

        }
        else if (item.show === 1){
            if (flag === 1){
                return (
                     <div className="wrapper">
                          ({flag})&nbsp;&nbsp;
                         <input type="text" className="question-input"  onChange={changeInput1}/>，{item.next}
                         {getTag (flag-1, show)}
                         <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                    </div>
                )
            }else if (flag === 2){
                return (
                     <div className="wrapper">
                          ({flag})&nbsp;&nbsp;
                         <input type="text" className="question-input"  onChange={changeInput2}/>，{item.next}
                         {getTag (flag-1, show)}
                         <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                    </div>
                )
            }else if (flag===3){
                 return (
                     <div className="wrapper">
                          ({flag})&nbsp;&nbsp;
                         <input type="text" className="question-input"  onChange={changeInput3}/>，{item.next}
                         {getTag (flag-1, show)}
                         <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——{item.title}
                    </div>
                )
            }
        }

    }

     // 获取输入的值
    const changeInput1 = (e) => {
        inputValue1 = e.target.value;
    }
    const changeInput2 = (e) => {
        inputValue2 = e.target.value;
    }
    const changeInput3 = (e) => {
        inputValue3 = e.target.value;
    }

    const getTag = (index, show) => {
        if (show[index] === 0) {
            return (<div className="cuoicon iconfont icon-cuo"></div>)
        } else if (show[index] === 1) {
            return (<div className="duiicon iconfont icon-zhengque-correct"></div>)
        } else {
            return "";
        }
    }

    return(
        <div>
            <Headerback
                showPage={2}
            />
            <div className="page course-page">
                 <div className="page-con">
                     <div className="problem">
                         <div className="problem-inner">
                             <p className="desc">{desc}</p>

                             <div className="question">
                                 <p className="title1">1、补全诗句</p>
                                 {
                                     filteredPool.map((item, index) => {
                                         return(
                                             getPro(gapPro[item])
                                         )
                                     })
                                 }
                             </div>

                             {
                                 schema.question.map((item, index) => {
                                  return (
                                    <div key={item.id} className="question">
                                      <p className="title">{item.id}、{item.desc}</p>
                                       <Question finish={finish} item={item} ref={refs[index]} yanz={[1,1,2]}></Question>
                                    </div>)
                                })
                             }

                         </div>
                     </div>
                     <button className="miaov-btn miaov-btn-md problem-btn" type="submit" onClick={handleSave} id ='btn'>提交</button>
                 </div>
            </div>
        </div>
    )

}