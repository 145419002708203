import React,{useState, useEffect} from "react";
import { Input } from 'antd-mobile'

export default function Search(props) {
    const [value, setValue] =useState(['']);

    // 获取输入的值
    function changeInput(e) {
        setValue(e)
    }
    // 键盘事件
    function getKeyCode(e) {
        // 当输入回车事件----把输入框内容给父组件
        if(e.keyCode ===13){
             props.getInputVal(value);
        }
    }

    return(
        <search>
                <div className="search-form">
                    <div className="search-container">
                        <i className="search-container i"></i>
                        <Input className="search-container input" placeholder="搜索知识卡片" clearable
                               onChange={changeInput} onKeyUp={getKeyCode} autoFocus={true}
                        />
                    </div>
                </div>
            </search>
    )
}
