import React, {useState} from "react";
import Search from "./search";
import Headerback from "../../component/headerback";
import Cardlist from "./cardlist";

let cardList=[
    {imgUrl:require("../../common/img/card/img4.png"),isGood:false, goodNum: Math.floor(Math.random()*100)},
    {imgUrl:require("../../common/img/card/img1.png"),isGood:false, goodNum: Math.floor(Math.random()*100)},
    {imgUrl:require("../../common/img/card/img5.png"),isGood:false, goodNum: Math.floor(Math.random()*100)},
    {imgUrl:require("../../common/img/card/img3.png"),isGood:false, goodNum: Math.floor(Math.random()*100)},
    {imgUrl:require("../../common/img/card/img7.png"),isGood:false, goodNum: Math.floor(Math.random()*100)},
    {imgUrl:require("../../common/img/card/img6.png"),isGood:false, goodNum: Math.floor(Math.random()*100)},
    {imgUrl:require("../../common/img/card/img2.png"),isGood:false, goodNum: Math.floor(Math.random()*100)},
    {imgUrl:require("../../common/img/card/img8.png"),isGood:false, goodNum: Math.floor(Math.random()*100)}
];

export default function Searchresult() {
    // 定义卡片列表容器，获取所有卡片的label标签
    const [list, setList] = useState([]);
    const [msg, setMsg] = useState('');

    // 定义函数接受子组件传递的数值
    function getInputVal(val) {
        // 查询数据
        if(val.trim()===''){
            setList([])
            setMsg('查无数据')
            return;
        }
        else {
            setMsg('搜索结果')
            setList(cardList);
        }
    }

    return (
        <div>
            <Headerback
                showPage={2}
            />
             <div className="page">
                <div className="page-con">
                    <Search getInputVal={getInputVal}/>
                    <div className="list">
                        <h3>{msg}</h3>
                        <Cardlist list={list} setList={setList}/>
                    </div>

                </div>
            </div>

        </div>
    )
}
